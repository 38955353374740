import React from "react";
import { ButtonGroup, ButtonPrimary } from "../../components/button";
import { SectionWrapper, SectionRow } from "../../components/section";
import { Headline1, Headline2, Paragraph } from "../../components/typography";
import { homeRoutes } from "../../data/routing";
import { FundraisingButton } from "../common/fundraising-button";

export default () => (
  <SectionWrapper>
    <SectionRow>
      <Headline1 as="h2">
        Chcę wesprzeć Fundację
      </Headline1>
      <Paragraph>
        Aby Fundacja mogła działać i realizować swoje cele, potrzebuje funduszy. Jeśli bliska jest Ci idea psychoedukacji i przełamywania tabu związanego ze zdrowiem psychicznym, wesprzyj nas wpłatą na konto. Numer znajdziesz poniżej.
      </Paragraph>
      <Paragraph>
        <strong>Dziękujemy za każdą złotówkę!</strong>
      </Paragraph>
      <Paragraph>
        Numer konta: <br />
        <strong>26 1140 2004 0000 3102 8216 3340</strong>
      </Paragraph>
      <Paragraph>
        Dane organizacji:<br />
        <strong>Fundacja Starsza Siostra</strong><br />
        <strong>ul. Żywiecka 50/15</strong><br />
        <strong>30-427 Kraków</strong>
      </Paragraph>
      <Paragraph>
        Tytuł przelewu:<br />
        <strong>Darowizna</strong>
      </Paragraph>
      <FundraisingButton />
    </SectionRow>
    <SectionRow>
      <Headline2 as="h3">Chcę zostać mentorem Fundacji</Headline2>
      <Paragraph>
        Oprócz wsparcia finansowego zależy nam na ciągłym rozwoju naszych własnych kompetencji i współpracy z doświadczonymi ekspertami. Być może posiadasz umiejętności, którymi chcesz się z nami podzielić i wspólnie z nami tworzyć Fundację?
      </Paragraph>
      <ButtonGroup align="center">
        <ButtonPrimary href={'#' + homeRoutes.contact.hash}>Skontaktuj się z nami</ButtonPrimary>
      </ButtonGroup>
    </SectionRow>
  </SectionWrapper>
);