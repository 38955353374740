import { PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Layout } from "../components/layout";
import { HeroNavigation, HomeNavigation } from "../components/navigation";
import { HomeRoutesInterface } from "../data/routing";
import { AboutSection, ContactSection, MissionSection, OfferSection, SupportSection } from "../sections/home";

const initialSectionRefs: { [key in keyof HomeRoutesInterface]: HTMLAnchorElement | null } = {
  mission: null,
  about: null,
  support: null,
  offer: null,
  contact: null,
};

const IndexPage: React.FC<PageProps> = () => {
  const sectionRefs = React.useRef<{ [key in keyof HomeRoutesInterface]: HTMLAnchorElement | null }>(initialSectionRefs);

  return (
    <Layout>
      <HomeNavigation homeRefs={sectionRefs.current} />
      <Hero>
        <LogoWrapper>
          Fundacja<br />
          Starsza<br />
          Siostra<br />
        </LogoWrapper>
        <HeroNavigation homeRefs={sectionRefs.current} />
      </Hero>
      <a href="#misja-fundacji" ref={ref => sectionRefs.current.mission = ref} />
      <MissionSection />
      <a href="#co-robimy" ref={ref => sectionRefs.current.about = ref} />
      <AboutSection />
      <a href="#chce-wesprzec" ref={ref => sectionRefs.current.support = ref} />
      <SupportSection />
      <a href="#dla-firm" ref={ref => sectionRefs.current.offer = ref} />
      <OfferSection />
      <a href="#kontakt" ref={ref => sectionRefs.current.contact = ref} />
      <ContactSection />
    </Layout>
  );
};

const Hero = styled.div.attrs({ className: "min-h-screen bg-primary pl-8 grid grid-cols-1 auto-rows-auto md:grid-rows-1 md:grid-cols-2 md:pl-36" })``;

const LogoWrapper = styled.h1.attrs({ className: "text-7xl text-white font-bold md:text-9xl flex items-center" })``;

export default IndexPage;
