import React from "react";
import styled from "styled-components";
import { GatsbyLinkPrimary, LinkPrimary } from "../../components/link";
import { SectionWrapper, SectionRow } from "../../components/section";
import { Headline1, Paragraph } from "../../components/typography";
import { homeRoutes } from "../../data/routing";
import LogoSvg from "../../images/logo-base.svg";

export default () => (
  <SectionWrapper>
    <SectionRow>
      <Headline1 as="h2">
        Misja Fundacji
      </Headline1>
      <Paragraph>
        Starsza Siostra powstała z potrzeby współdziałania na rzecz psychoedukacji i wsparcia w zakresie zdrowia mentalnego. Statystyki pokazują, że mimo zwiększającej się świadomości na temat funkcjonowania mechanizmów ludzkiej psychiki, kolejki do gabinetów terapeutycznych są coraz dłuższe. W Fundacji wierzymy, że każda osoba powinna mieć szansę na poznanie swoich zasobów i wypracowanie mechanizmów pomocnych w przywracaniu psychicznego dobrostanu. Chcemy przyczyniać się do zmniejszania poczucia osamotnienia i bezradności. Jednocześnie pragniemy zwiększać świadomość własnej sprawczości i tworzyć sieci społecznego wsparcia.
      </Paragraph>
      <Paragraph>
        Swoją misję realizujemy poprzez spotkania psychoedukacyjne skierowane do młodzieży i osób dorosłych. Organizujemy warsztaty, szkolenia i webinary. W planach mamy również stworzenie platformy edukacyjnej i ofertę niskopłatnej terapii oraz konsultacji psychologicznych.
      </Paragraph>
      <Paragraph>
        Działamy również w obszarze szeroko rozumianego rozwoju zawodowego. Edukujemy i podpowiadamy narzędzia, dzięki którym praca staje się obszarem realizowania wartości istotnych dla danej osoby.
      </Paragraph>
      <Paragraph>
        Cieszymy się, że jesteś tu z nami!
      </Paragraph >
      <Paragraph>
        Jeśli misja Fundacji jest Ci bliska, zapraszamy do wsparcia nas poprzez darowizny. Więcej informacji znajdziesz w skecji <LinkPrimary href={'#' + homeRoutes.support.hash}>chcę wesprzeć Fundację</LinkPrimary>.
        Zgromadzone środki w całości przeznaczamy na działania <GatsbyLinkPrimary to={'/statut/'}>statutowe</GatsbyLinkPrimary>.
      </Paragraph>
    </SectionRow>
    <SectionRow>
      <Logo src={LogoSvg} alt="Logo Fundacja Starsza Siostra" width="1" height="1" />
    </SectionRow>
  </SectionWrapper>
);

const Logo = styled.img.attrs({ className: "mx-auto md:w-1/2 block aspect-square" })``;