import styled from "styled-components";
import { theme } from "../../styles/styles";

export const List = styled.ul.attrs({ className: "my-4" })``;

export const ListItem = styled.li.attrs({ className: "text-2xl text-justify mb-4 flex items-center md:pl-4" })`
  &:before {
    content: "\\2022";
    color: ${(theme?.colors as any)?.primary};
    font-weight: bold;
    display: block;
    margin-right: 1rem;
    font-size: 3rem;
    margin-top: -0.25rem;
  }
`;