import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { ImageWrapper } from "../../components/image";
import { List, ListItem } from "../../components/list";
import { SectionCell, SectionRow, SectionWrapper } from "../../components/section";
import { Headline1, Paragraph } from "../../components/typography";

export default () => (
  <SectionWrapper>
    <SectionCell modifier="md:order-1">
      <Headline1 as="h2">Co robimy</Headline1>
      <Paragraph>
        Cześć! Dzień dobry!
      </Paragraph>
      <Paragraph>
        Z tej strony Adriana Błażej, założycielka Fundacji Starsza Siostra. Chcę Ci przekazać kilka słów na temat naszych działań.
      </Paragraph>
      <Paragraph>
        Jesteśmy zespołem terapeutek, psycholożek i coachek, które ogromną wartość widzą w psychoedukacji. Swoją wiedzą i zasobami dzielimy się na kilka sposobów:
      </Paragraph>
      <List>
        <ListItem>prowadzimy warsztaty dla młodzieży i dorosłych,</ListItem>
        <ListItem>organizujemy szkolenia i webinary,</ListItem>
        <ListItem>tworzymy sieci wsparcia społecznego.</ListItem>
      </List>
      <Paragraph>
        Patrzymy z przerażeniem na statystyki dotyczące depresji i liczby samobójstw wśród młodych osób. Uważamy, że system edukacji wymaga głębokich zmian i nie odpowiada na potrzeby dzieci i młodzieży, nie wspiera ich w rozwiązywaniu problemów.
      </Paragraph>
    </SectionCell>
    <SectionCell modifier="md:order-2">
      <ImageWrapper>
        <StaticImage
          src="../../images/o-nas.jpg"
          alt="Zdjęcie fundatorki Adriany Błażej"
          placeholder="blurred"
          layout="fullWidth"
        />
      </ImageWrapper>
    </SectionCell>
    <SectionCell modifier="md:order-4">
      <Paragraph>
        Młodzi ludzie są często samotni, czują, że nigdzie nie przynależą, że nie są wiele warci - to poczucie chcemy zmieniać. Wzmacniać w nich wiarę we własne możliwości, dawać im wiedzę na temat mechanizmów funkcjonowania ludzkiej psychiki. Po to, by w dorosłość wchodzili z większą pewnością siebie, poczuciem bezpieczeństwa, emocjonalnej stabilności i tym samym otrzymywali szansę na pełne i szczęśliwe życie.
      </Paragraph>
      <Paragraph>
        Ci młodzi ludzie to przyszli dorośli. A współcześni dorośli również zmagają się z szeregiem wyzwań i problemów w obszarze zdrowia psychicznego. Pokazują to m.in. statystyki dotyczące zwolnień lekarskich przyznawanych z tytułu obciążeń psychicznych. W okresie pandemii liczba tego typu zwolnień wzrosła o ponad 10% w porównaniu do lat poprzednich.
        Chcemy coś zrobić w tej sprawie a pracy mamy mnóstwo. Plany mamy dalekosiężne i ambitne. To, czy uda nam się je zrealizować, zależy m.in. od tego, czy na nasze działania pozyskamy odpowiednie fundusze. Jeśli chcesz dołożyć cegiełkę do tego, co realizujemy, przejdź do zakładki “chcę wesprzeć Fundację”. Dziękujemy!
      </Paragraph>
    </SectionCell>
    <SectionCell modifier="md:order-3">
      <ImageWrapper>
        <StaticImage
          src="../../images/o-nas-2.jpg"
          alt="Zdjęcie kobiety trzymającej książki na dłoni"
          placeholder="blurred"
          layout="fullWidth"
        />
      </ImageWrapper>
    </SectionCell>
  </SectionWrapper>
);