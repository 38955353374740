import styled from "styled-components";

interface LinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> { }

interface ButtonGroupProps {
  align?: 'justify' | 'center' | 'right';
}

const Button = styled.a.attrs<LinkProps>({ className: "px-8 py-4 text-3xl font-bold text-white rounded-full transition-colors text-center md:text-4xl" }) <LinkProps>``;

export const ButtonPrimary = styled(Button).attrs({ className: "bg-primary hover:bg-primary-dark" })``;

export const ButtonSecondary = styled(Button).attrs({ className: "bg-secondary hover:bg-secondary-dark" })``;

export const ButtonTertiary = styled(Button).attrs({ className: "bg-tertiary hover:bg-tertiary-dark" })``;

export const ButtonGroup = styled.div.attrs<ButtonGroupProps>(
  ({ align }) => ({ className: `py-4 flex${align === "center"? ' justify-center' : ''}${align === "justify"? ' justify-between' : ''}${align === "right"? ' flex-end' : ''}` })
) <ButtonGroupProps>``;