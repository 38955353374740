import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { ImageWrapper } from "../../components/image";
import { List, ListItem } from "../../components/list";
import { SectionCell, SectionRow, SectionWrapper } from "../../components/section";
import { Headline1, Paragraph } from "../../components/typography";
import { FundraisingButton } from "../common/fundraising-button";

export default () => (
  <SectionWrapper>
    <SectionCell>
      <Headline1 as="h2">Dla firm</Headline1>
      <Paragraph>
        Prowadzimy szkolenia i webinary edukacyjne dla firm z zakresu:
      </Paragraph>
      <List>
        <ListItem>zarządzania stresem</ListItem>
        <ListItem>zarządzania emocjami</ListItem>
        <ListItem>zarządzania różnorodnością ( w tym: różnicami pokoleniowymi)</ListItem>
        <ListItem>zarządzania zmianą</ListItem>
        <ListItem>udzielania feedbacku</ListItem>
        <ListItem>komunikacji (trudne rozmowy, mediacje - szczególnie skierowane do działów HR)</ListItem>
        <ListItem>mapowania talentów</ListItem>
        <ListItem>motywacji i zaangażowania</ListItem>
        <ListItem>budowania asertywności</ListItem>
        <ListItem>dbania o zdrowie mentalne pracowników</ListItem>
        <ListItem>rozwijania elastyczności psychicznej</ListItem>
        <ListItem>wysokosprawczości</ListItem>
        <ListItem>wysokiej wrażliwości (wrażliwości sensorycznej i emocjonalnej).</ListItem>
      </List>
      <Paragraph>
        Forma online i stacjonarnie.
      </Paragraph>
    </SectionCell>
    <SectionCell>
      <ImageWrapper>
        <StaticImage
          src="../../images/dla-firm-2.jpg"
          alt="Zdjęcie przedstawiające wizytówkę"
          placeholder="blurred"
          layout="fullWidth"
        />
      </ImageWrapper>
    </SectionCell>
    <SectionRow>
      <FundraisingButton />
    </SectionRow>
  </SectionWrapper>
);