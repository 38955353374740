import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { IconFacebook, IconLinkedin } from "../../components/icons";
import { ImageWrapper } from "../../components/image";
import { LinkPrimary } from "../../components/link";
import { SectionCell, SectionWrapper } from "../../components/section";
import { Headline1, Paragraph } from "../../components/typography";
import { socialLinks } from "../../data/social-links";

export default () => (
  <SectionWrapper>
    <SectionCell modifier="md:order-2">
      <Headline1 as="h2">Kontakt</Headline1>
      <Paragraph>
        Adres fundacji:<br />
        <strong>ul. Żywiecka 50/15</strong><br />
        <strong>30-427 Kraków</strong>
      </Paragraph>
      <Paragraph>
        KRS: <strong>0000946174</strong><br />
        NIP: <strong>6793230889</strong>
      </Paragraph>
      <Paragraph>
        Adres e-mail:<br />
        <LinkPrimary href="mailto:kontakt@starszasiostra.pl"><strong>kontakt@starszasiostra.pl</strong></LinkPrimary>
      </Paragraph>
      <Paragraph>
        Media społecznościowe:<br />
        {Object.keys(socialLinks).map(key => (
            <SocialLink key={`social_link_${key}`} href={socialLinks[key]} target="_blank" rel="nofollow noreferrer">
              {'facebook' === key && <IconFacebook />}
              {'linkedin' === key && <IconLinkedin />}
            </SocialLink>
          ))}
      </Paragraph>
    </SectionCell>
    <SectionCell modifier="md:order-1">
      <ImageWrapper>
        <StaticImage
          src="../../images/kontakt.jpg"
          alt="Zdjęcie kartki pocztowej"
          placeholder="blurred"
          layout="fullWidth"
        />
      </ImageWrapper>
    </SectionCell>
  </SectionWrapper>
);

const SocialLink = styled.a.attrs({className: "w-20 h-20 inline-block bg-primary/80 p-4 mr-4 rounded-full hover:bg-primary"})``;